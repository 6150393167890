<template>
  <div class="digital">
    <div class="digital-content w">
      <h4>数字政务</h4>
      <div class="text">
        <!-- <span>物业服务管理需求</span> -->
        <!-- <p>
          基于BIM模型的三维可视化智慧园区服务平台，<em>实时展现园区动态信息</em>
          ，提供满足园区未来发展需求的集成性管理系统。通过融合BIM模型、IOT技术、虚拟现实技术等，实时呈现园区客观状态，<em
            >支撑工程监管及维护、智慧园区综合服务，以及运营管理的业务需求。</em
          >
        </p> -->
        <p>
          数字政务系统，指的是通过融合A.I.技术和政务大数据，打造线上数字化平台的功能。以人民群众为服务中心，以数据共享为基础，搭建线上信息化渠道，致力打造让企业和群众办事实现便利数字化的政务服务模式，让企业和群众办事更简单、更迅速。
        </p>
      </div>
      <div class="imgs w">
        <img :src="imgData1s" alt="" />
      </div>
      <div class="digital-list">
        <span v-for="(item,index) of imgs" :key="index" @click="add(item.imgData1)">
          <img :src="item.imgData2" alt="" />
        </span>
      </div>
    </div>
    <div class="zation">
      <div class="text-title">
        <i>可视化数字展示</i>
        <p>
          GIS+BIM可视化/数字大屏产品，提供智能化应用服务
        </p>
      </div>
      <div class="image-display">
          <!-- <span v-for="item of GovernmentData" :key="item.id">
              <div class="zation-img">
                <img :src="baseIP+imageShowAPI+item.imageUri" alt="">
              </div>
              <div class="box">{{item.title}}</div>
          </span> -->
          <span>
              <div class="zation-img">
                <img src="../assets/imgs/pic1.jpg" alt="">
              </div>
              <div class="box">智慧园区</div>
          </span>
          <span>
              <div class="zation-img">
                <img src="../assets/imgs/pic2.jpg" alt="">
              </div>
              <div class="box">智慧园区</div>
          </span>
          <span>
              <div class="zation-img">
                <img src="../assets/imgs/pic3.jpg" alt="">
              </div>
              <div class="box">智慧园区</div>
          </span>
          <span>
              <div class="zation-img">
                <img src="../assets/imgs/pic4.jpg" alt="">
              </div>
              <div class="box">智慧园区</div>
          </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getColumnContent } from "../api/index.js";
import store from "@/store";
export default {
  data () {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      GovernmentData:[],
      imgData1s:'',
      imgs:[
        {
          imgData1:require('../assets/imgs/1-1.jpg'),
          imgData2:require('../assets/imgs/1.jpg'),
        },
        {
          imgData1:require('../assets/imgs/2-1.jpg'),
          imgData2:require('../assets/imgs/2.jpg'),
        },
        {
          imgData1:require('../assets/imgs/3-1.jpg'),
          imgData2:require('../assets/imgs/3.jpg'),
        },
        {
          imgData1:require('../assets/imgs/4-1.jpg'),
          imgData2:require('../assets/imgs/4.jpg'),
        }
      ]
    }
  },
  created () {

    getColumnContent({optionType:'5'}).then((res)=>{
      if(res.code === 0){
        this.GovernmentData = res.data.content.slice(0,4)
      }
    })
  },
  mounted(){
    this.imgData1s = this.imgs[0].imgData1
  },
  methods: {
    add(val){
      this.imgData1s = val
    }
  }
};
</script>

<style lang="scss" scoped>
.digital-content {
  margin-top: 70px;
  margin-bottom: 100px;
  h4 {
    height: 40px;
    line-height: 40px;
    font-size: 34px;
    color: #333333;
    text-align: center;
    font-weight: normal;
  }
  .text {
    margin-top: 50px;
    span {
      display: block;
      height: 20px;
      line-height: 20px;
      font-size: 18px;
      color: #333333;
      text-align: left;
      
    }
    p {
      line-height: 30px;
      font-size: 18px;
      color: #999999;
      text-indent: 2em;
      margin-top: 50px;
      text-align: left;
      em {
        font-style: normal;
      }
      em:nth-child(1) {
        color: #0266ba;
      }
      em:nth-child(2) {
        color: #e43838;
      }
    }
  }
  .imgs {
    height: 489px;
    margin-top: 35px;
    overflow: hidden;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
      transition: all 0.9s;
    }
    img:hover{
      transform: scale(1.2);
    }
  }
  .digital-list {
    margin-top: 16px;
    display: flex;
    justify-content: left;
    span {
      display: inline-block;
      width: 334px;
      height: 156px;
      margin-right: 20px;
      cursor: pointer;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.9s;
      }
      img:hover{
      transform: scale(1.2);
    }
    }
    span:last-child {
      margin-right: 0;
    }
  }
}
.zation {
    height: 100%;
  .text-title {
    width: 665px;
    line-height: 120px;
    margin: 0 auto;
    padding-top: 90px;
    box-sizing: border-box;
    i {
      display: block;
      font-style: normal;
      height: 40px;
      line-height: 40px;
      font-size: 36px;
      color: #333333;
    }
    p {
      line-height: 40px;
      font-size: 18px;
      color: #999999;
      margin: 10px 0;
    }
  }
  .image-display{
      margin-top: 57px;
      margin-bottom: 70px;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      span{
          display: inline-block;
          width: 665px;
          height: 373px;
          position: relative;
          cursor: pointer;
          .zation-img{
            width: 665px;
            height: 373px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                transition: all 0.9s;
            }
            img:hover {
              transform: scale(1.2);
            }
          }
      }
      .box{
          width: 665px;
          height: 47px;
          line-height: 47px;
          background: rgba(0,0,0,0.5);
          position: absolute;
          bottom: 0;
          color: #ffffff;
          font-size: 20px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
      }
      span:nth-child(1){
          margin-right: 70px;
          margin-bottom: 40px;
      }
      span:nth-child(2){
          margin-bottom: 40px;
      }
      span:nth-child(3){
          margin-right: 70px;
      }
  }
}
</style>